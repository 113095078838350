import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {Version} from "../../model/changelog.model";


@Component({
    selector: 'app-changelog-dialog',
    templateUrl: './changelog-dialog.component.html',
    styleUrl: './changelog-dialog.component.scss',
    standalone: false
})
export class ChangelogDialogComponent {
  showAll = false;
  readonly data = inject<{new: Version[], all: Version[]}>(MAT_DIALOG_DATA);
  constructor(protected translate: TranslateService) {

  }

}
