<ngx-charts-bar-vertical-2d
        [view]="[700, 400]"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [legendTitle]="legendTitle"
        [results]="data"
        [scheme]="colorScheme"
    >
</ngx-charts-bar-vertical-2d>