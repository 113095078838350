import { createReducer, on } from '@ngrx/store';
import { ConversationsActions } from '../actions/conversations.actions';
import {Conversation, ConversationStub} from "../../model/conversation.model";
import {State} from "./index";
import {ChatMessage} from "../../model/chat-message";
import * as uuid from 'uuid';
import {GroupActions} from "../actions/group.actions";

export const EMPTY_CONVERSATION = {messages: [], documents: []} as Conversation;
export interface ConversationsState {
    conversations: Array<ConversationStub>;
    activeConversationId?: string;
    activeConversation: boolean;
    activeConversationDetail: Conversation;
    latestConversationId?: {id: string | undefined, requireNew: boolean};
    transientResponse: ChatMessage;
}

export const initialState: ConversationsState = {
    conversations: [],
    activeConversation: false,
    activeConversationDetail: EMPTY_CONVERSATION,
    transientResponse: {ongoing: false, chunk_message: ""}

};

export const reducer = createReducer(
  initialState,
    on(ConversationsActions.conversationsLoadSuccess, (state, {data, latestConversationId, requireNewConversation}) => (
        {...state, conversations: data,
            latestConversationId: {id: latestConversationId, requireNew: requireNewConversation},
        })),
    on(ConversationsActions.conversationActivate, (state, {conversationId}) => ({...state, activeConversation: true, activeConversationId: conversationId})),
    on(ConversationsActions.conversationRename, (state, {conversationId, newTitle}) => ( {...state, conversations: state.conversations.map((conv) => conv.id === conversationId ? {...conv, title: newTitle} : conv)})),
    on(ConversationsActions.conversationRenameSuccess, (state, {conversationId, newTitle}) => ( {...state, conversations: state.conversations.map((conv) => conv.id === conversationId ? {...conv, title: newTitle} : conv)})),
    on(ConversationsActions.conversationDelete, (state, {conversationId}) => ({...state, conversations: state.conversations.filter((conv) => conv.id !== conversationId)})),
    on(ConversationsActions.conversationNewSuccess, (state, {conversation}) => ({...state, conversations: [...state.conversations, conversation], latestConversationId: {id: conversation.id, requireNew: false}})),
    on(ConversationsActions.conversationDetailLoadSuccess, (state, {data}) => ({...state, activeConversationDetail: data})),
    on(ConversationsActions.conversationDetailAddResponseChunk, (state, {response_chunk}) => (
        {...state,  transientResponse: {ongoing: true, chunk_message: response_chunk.chunk_message}})),

    on(ConversationsActions.conversationDetailAddFinalResponse, (state, {response}) => (
        {...state,  transientResponse: {ongoing: false, chunk_message: ""}, activeConversationDetail: {messages:
                        state.activeConversationDetail.messages.map(
                            (conv) => conv.ongoing ? {...conv, ongoing: false,  answer: response.final_message || "NO_ANSWER", documents: response.documents || []} : conv
                        )
                    ,
                documents: state.activeConversationDetail.documents
            }
        })),
    on(ConversationsActions.conversationDetailAddQuestion, (state, {question}) => ({...state,
        modified_at: new Date().toString(),
        activeConversationDetail: {documents: state.activeConversationDetail.documents,
            messages: [...state.activeConversationDetail.messages, {
            created_at: new Date().toString(),
            id: uuid.v4(),
            question: question,
            answer: "",
            llm: "",
            feedback: "",
                documents: [],
                run_in_additional_information: "",
                status: "valid",
            ongoing: true}
        ]},
           transientResponse: {ongoing: true, chunk_message: "\n\n\n\n\n\n"}
    }
    )),
    on(ConversationsActions.conversationDeleteFile, (state, {conversationId, fileId}) => ({...state, activeConversationDetail: {messages: state.activeConversationDetail.messages, documents: state.activeConversationDetail.documents.filter((doc) => doc.id !== fileId)}})),
    on(ConversationsActions.deactivateActiveConversation, (state) => ({...state, activeConversation: false, activeConversationId: undefined, activeConversationDetail: EMPTY_CONVERSATION})),
    on(GroupActions.groupsActivate, ((state) => ({...initialState})))
    );


export const selectActiveConversationId = (state: State) => state.conversations.activeConversationId;

