import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import changelog from '../../changelog/changelog.json';
import {MatDialog} from "@angular/material/dialog";
import {ChangelogDialogComponent} from "../changelog-dialog/changelog-dialog.component";
import {LocalStorageService} from "../../service/local-storage.service";
import {Changelog, Version} from "../../model/changelog.model";
import {wobbleAnimation} from "angular-animations";

@Component({
    selector: 'app-changelog',
    templateUrl: './changelog.component.html',
    styleUrl: './changelog.component.scss',
    animations: [
        wobbleAnimation()
    ],
    standalone: false
})
export class ChangelogComponent implements OnInit{

  changelog: Changelog = changelog[this.translate.currentLang as keyof typeof changelog];
  newFlag = false;
  lastDisplayed: Date = new Date();
  latestChangelogEntry: Version = {title: "No changelog entry", body: "", date: null, version: null};

  constructor(private localStore: LocalStorageService, private http: HttpClient, private translate: TranslateService, private dialog: MatDialog) {

    translate.onLangChange.subscribe((e) => {
        this.changelog = changelog[e.lang as keyof typeof changelog];
        this.latestChangelogEntry = this.changelog.versions
            .filter((v) => v.date)
            .reduce((acc, version) => {
                if (new Date(version.date || 0) > new Date(acc.date || 0)){
                    return version;
                }
                return acc;
            });
    });

  }

    ngOnInit(): void {
        this.lastDisplayed = this.localStore.getChangelogLastDisplayed();
        if (this.getNewEntries(this.lastDisplayed).length > 0){
            this.newFlag = true;
        }

    }

  showChangelog() {
    console.log(this.lastDisplayed);
    this.dialog.open(ChangelogDialogComponent, {height: '80%', data: {new: this.getNewEntries(this.lastDisplayed), all: this.getAllEntries()}});
    this.localStore.setChangelogLastDisplayedNow();
  }

  getNewEntries( lastChecked: Date){
    return this.changelog.versions.filter((version) => version.date && new Date(version.date) > lastChecked);
  }

  getAllEntries(){
    return this.changelog.versions.filter((version) => version.date);
  }

}
