import {isDevMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MsalBroadcastService, MsalInterceptor, MsalModule, MsalService} from "@azure/msal-angular";
import {
    AccountInfo,
    BrowserCacheLocation,
    InteractionStatus,
    InteractionType,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication
} from "@azure/msal-browser";
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {filter, first, map, Observable} from "rxjs";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {LoginFailedComponent} from './components/login-failed/login-failed.component';
import {ChatComponent} from './components/chat/chat.component';
import {MatInputModule} from "@angular/material/input";
import {MatCardModule} from "@angular/material/card";
import {MatChipsModule} from "@angular/material/chips";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {Store, StoreModule} from '@ngrx/store';
import {metaReducers, reducers, State} from './redux/reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {EffectsModule} from '@ngrx/effects';
import {GroupEffects} from "./redux/effects/group.effects";
import {ConversationsEffects} from "./redux/effects/conversations.effects";
import {DateAgoPipe} from './pipes/date-ago.pipe';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MatBadgeModule} from "@angular/material/badge";
import {
    MatAccordion,
    MatExpansionModule
} from "@angular/material/expansion";
import {FeedbackComponent} from './components/feedback/feedback.component';
import {SafePipe} from "safe-pipe";
import {OrderByPipe} from './pipes/order-by.pipe';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MarkdownModule, MARKED_OPTIONS, MarkedOptions, MarkedRenderer} from "ngx-markdown";
import {MatOption} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {EditTitleComponent} from './components/edit-title/edit-title.component';
import {AppEffects} from "./redux/effects/app.effects";
import {ErrorDialogComponent} from './components/error-dialog/error-dialog.component';
import {AppActions} from "./redux/actions/app.actions";
import {FileUploadModule} from "@iplab/ngx-file-upload";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {ComplianceDialogComponent} from "./components/compliance-dialog/compliance-dialog.component";
import {Router} from "@angular/router";
import {LocalStorageService} from "./service/local-storage.service";
import {Group} from "./model/group.model";
import {AddConversationButtonComponent} from './components/add-conversation-button/add-conversation-button.component';
import {NavigationActionTiming, StoreRouterConnectingModule} from '@ngrx/router-store';
import {TenantContainerComponent} from './components/tenant-container/tenant-container.component';
import {MatButtonToggle, MatButtonToggleGroup} from "@angular/material/button-toggle";
import {FilterConversationsPipe} from './pipes/filter-conversations.pipe';
import {StreamingContainerComponent} from "./components/streaming-container/streaming-container.component";
import {ChangelogComponent} from './components/changelog/changelog.component';
import {ChangelogDialogComponent} from "./components/changelog-dialog/changelog-dialog.component";
import {AdminContainerComponent} from './components/admin-container/admin-container.component';
import {UsageChartComponent} from './components/usage-chart/usage-chart.component';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {AdminOverviewComponent} from './components/admin-overview/admin-overview.component';
import {AdminPromptlabComponent} from './components/admin-promptlab/admin-promptlab.component';
import {PromptLabEffects} from './redux/effects/prompt-lab.effects';
import {ErrorMessageType} from "./model/error-message.model";
import { PromptSelectionComponent } from './components/prompt-selection/prompt-selection.component';
import {MatCheckbox} from "@angular/material/checkbox";
import {NgxColorsModule} from "ngx-colors";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginFailedComponent,
    ChatComponent,
    DateAgoPipe,
    FeedbackComponent,
      OrderByPipe,
      FileUploadComponent,
      EditTitleComponent,
      ErrorDialogComponent,
      SafeHtmlPipe,
      ComplianceDialogComponent,
      AddConversationButtonComponent,
      TenantContainerComponent,
      FilterConversationsPipe,
      StreamingContainerComponent,
      ChangelogComponent,
      ChangelogDialogComponent,
      AdminContainerComponent,
      UsageChartComponent,
      AdminOverviewComponent,
      AdminPromptlabComponent,
      PromptSelectionComponent,
  ],
    imports: [
        //generic
        BrowserModule,
        AppRoutingModule,
        MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
            auth: {
                clientId: environment.msalConfig.auth.clientId,
                authority: environment.msalConfig.auth.authority,
                redirectUri: window.location.origin,
                postLogoutRedirectUri: window.location.origin,
                navigateToLoginRequestUrl: true
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: true, // set to true for IE 11
            },
            system: {
                loggerOptions: {
                    piiLoggingEnabled: false,
                    logLevel: LogLevel.Verbose
                }
            }
        }), {
            interactionType: InteractionType.Redirect, // MSAL Guard Configuration
            authRequest: {
                scopes: ['user.read']
            },
            loginFailedRoute: "/login-failed"
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map<string, Array<string>>([["https://graph.microsoft.com/v1.0/me", ["user.read"]], [environment.apiBase, [environment.apiScope]]])
        }),
        MatIconModule,
        MatDialogModule,
        MatListModule,
        MatMenuModule,
        MatToolbarModule,
        MatInputModule,
        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        FormsModule,
        MatSidenavModule,
        StoreModule.forRoot(reducers, {metaReducers}),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([GroupEffects, ConversationsEffects, AppEffects, PromptLabEffects]),
        ReactiveFormsModule,
        MatBadgeModule,
        SafePipe,
        MatSnackBarModule,
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatTooltipModule,
        MatOption,
        BrowserAnimationsModule,
        StoreRouterConnectingModule.forRoot({
            navigationActionTiming: NavigationActionTiming.PostActivation
        }),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: !isDevMode(), trace: isDevMode()}),
        MatButtonToggle,
        MatSelectModule,
        MatButtonToggleGroup,
        MatCheckbox,

        //llm chat
        MatCardModule,
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MARKED_OPTIONS,
                useFactory: markedOptionsFactory,
            }
        }),
        ClipboardModule,
        FileUploadModule,
        MatProgressSpinner,

        //admin
        NgxChartsModule,
            //promptlab
            MatSlideToggleModule,
            MatAccordion,
            NgxColorsModule,
            MatExpansionModule,

    ],
  providers: [
    {provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true},
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private router: Router, private store: Store<State> , private dialog: MatDialog, private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private localStore: LocalStorageService) {

  }

    errors$ = this.store.select((state: State) => state.app.errors)
    activeTenant$ = this.store.select((state: State) => state.groups.activeGroup);
    noGroupPermissions$ = this.store.select((state: State) => state.groups.noGroupPermissions);
    currentTCVersion = 1;

    public account: Observable<AccountInfo | null> = this.msalBroadcastService
    .inProgress$
    .pipe(
      filter(status => status == InteractionStatus.None),
      map(() => {
        const instance: IPublicClientApplication = this.authService.instance;
        const activeAccount: AccountInfo | null = instance.getActiveAccount();
        const accounts: AccountInfo[] = instance.getAllAccounts();
        if (activeAccount != null) return activeAccount;
        if (accounts.length > 0) {
          const [firstAccount] = accounts;
          instance.setActiveAccount(firstAccount);
          return firstAccount;
        }
        return null;
      })
    );

    private error = this.errors$.subscribe((errors) => {
        if (errors.length > 0) {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
                data: errors
            });
            dialogRef.afterClosed().subscribe(result => {
                this.store.dispatch(AppActions.appClearErrors())
                if (errors.filter(e => e.type === ErrorMessageType.CONVERSATION_DETAIL_LOAD_FAILURE).length > 0) {
                    this.activeTenant$.pipe(first()).subscribe((tenant) => {
                        if (tenant)
                            this.router.navigate(['tenant', tenant.id, 'conversation'], {relativeTo: this.router.routerState.root});
                    });
                }
            });
        }
    });

    public showComplianceDialog(tenant: Group) {
        let dialogRef = this.dialog.open(ComplianceDialogComponent, {
            data: tenant,
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            this.store.dispatch(AppActions.appAcknowledgeComplianceDialog({tenant: tenant, version: this.currentTCVersion}))
            if(tenant.tc.canMute) {
                this.localStore.setTermsConditionsConsentVersion(tenant.id, tenant.tc.version)
            }

        });
    }


    private noGroupPermissions = this.noGroupPermissions$.subscribe((noGroupPermissions) => {
        if (noGroupPermissions) {
            this.router.navigate(['/login-failed']);
        }
    });



}

// function that returns `MarkedOptions` with renderer override
export function markedOptionsFactory(): MarkedOptions {
    const renderer = new MarkedRenderer();


    return {
        renderer: renderer,
        gfm: true,
        breaks: false,
        pedantic: false,
    };
}
