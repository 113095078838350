import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Prompt, PromptAppearance} from "../../model/prompt.model";
import {mapToPromptDTO, tenantLLMs, tenantPrompts, tenantTopics} from "../../redux/reducers/prompt-lab.reducer";
import {PromptLabActions} from "../../redux/actions/prompt-lab.actions";
import {Store} from "@ngrx/store";
import {State} from "../../redux/reducers";
import {activeTenant} from "../../redux/reducers/group.reducer";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-admin-promptlab',
    templateUrl: './admin-promptlab.component.html',
    styleUrl: './admin-promptlab.component.scss',
    standalone: false
})
export class AdminPromptlabComponent implements OnInit{



  forms: IHash = {};
  llms$  = this.store.select(tenantLLMs);
  styles: PromptAppearance[] = [
      {color: 'red', icon: 'auto_awesome'},
      {color: 'orange', icon: 'auto_awesome'},
      {color: 'green', icon: 'auto_awesome'},
      {color: 'blue', icon: 'auto_awesome'}
  ];
  tenantTopics$ = this.store.select(tenantTopics);
  prompts$ = this.store.select(tenantPrompts);
  userLevels = [{tier: 'users', name: 'Tenant Users'}, {tier: 'moderators', name: 'Moderators'}, {tier: 'superuser', name: 'Super Users'}, {tier: 'admins', name: 'Admins'}];
  showPromptHint = false;

  constructor(private fb: FormBuilder, private store: Store<State>, private translate: TranslateService) {

  }

    ngOnInit() {
      this.store.select(activeTenant).subscribe(
        tenant => {
          if (tenant) {
            this.store.dispatch(PromptLabActions.loadPromptLab());
            this.store.dispatch(PromptLabActions.loadPrompts());
          }
        }
      )
    }

  addPrompt(){
    this.store.dispatch(PromptLabActions.createNewPrompt())
  }

  deletePrompt(p: Prompt){
    this.store.dispatch(PromptLabActions.deletePrompt({prompt: mapToPromptDTO(p)}));
  }

  updatePrompt(form: FormGroup, initialPrompt: Prompt){
        let prompt = {} as Prompt;
        prompt.availableTopics = form.value.availableTopics;
        prompt.defaultEnabledTopics = form.value.defaultEnabledTopics;
        prompt.title = form.value.title;
        prompt.label = initialPrompt.label;
        prompt.appearance = {icon: 'auto_awesome', color: form.value.appearance};
        prompt.accessibleBy = form.value.accessibleBy;
        prompt.llm = form.value.llm;
        prompt.goalDescription = form.value.goalDescription;
        prompt.grounding = form.value.grounding;
        prompt.default = form.value.default;
      this.store.dispatch(PromptLabActions.savePrompt({prompt: mapToPromptDTO(prompt)}));
  }

    savePrompt(form: FormGroup, initialPrompt: Prompt){
        let prompt = {} as Prompt;
        prompt.availableTopics = form.value.availableTopics;
        prompt.defaultEnabledTopics = form.value.defaultEnabledTopics;
        prompt.title = form.value.title;
        prompt.label = 'new_prompt'
        prompt.appearance = {icon: 'auto_awesome', color: form.value.appearance};
        prompt.accessibleBy = form.value.accessibleBy;
        prompt.llm = form.value.llm;
        prompt.goalDescription = form.value.goalDescription;
        prompt.grounding = form.value.grounding;
        prompt.default = form.value.default;
        this.store.dispatch(PromptLabActions.saveNewPrompt({prompt: mapToPromptDTO(prompt)}));
    }

  getForm(p: Prompt) {
      if (!this.forms[p.id]) {

        this.forms[p.id] = this.fb.group({
          title: [p.title, [Validators.minLength(5), Validators.maxLength(50), Validators.required, Validators.pattern(/^[a-zA-Z0-9_\-\s]*$/)]],
          appearance: [p.appearance.color || 'red', Validators.required],
          accessibleBy: [p.accessibleBy || 0, Validators.required],
          availableTopics:[p.availableTopics],
          defaultEnabledTopics:[p.defaultEnabledTopics],
          llm: [p.llm, Validators.required],
          goalDescription: [p.goalDescription, [Validators.required, Validators.minLength(5), Validators.maxLength(4000)]],
          grounding: p.grounding || false,
          default: [p.default || false, Validators.required]
        });
      }
    return this.forms[p.id];
  }

}



interface IHash {
  [details: string] : FormGroup;
}


