import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import {ChatMessage} from "../../model/chat-message";
import {Observable, Subject} from "rxjs";

@Component({
    selector: 'app-streaming-container',
    templateUrl: './streaming-container.component.html',
    styleUrl: './streaming-container.component.scss',
    standalone: false
})
export class StreamingContainerComponent implements AfterViewInit{

  @Input() public hidden: boolean = true;
  @Input() public stream: Observable<ChatMessage>  = new Subject<ChatMessage>();
  protected current = '';
  private eventCount = 0;

  ngAfterViewInit() {
    this.stream.subscribe((message) => {
      if(this.eventCount % 1 == 0){
        this.current = message.chunk_message || 'No message';
        this.detector.detectChanges();
      }
      this.eventCount++;
      if(!message.ongoing){
        this.eventCount = 0;
        this.current = '';
      }
    });
  }

  constructor(private detector: ChangeDetectorRef) {

  }

}
