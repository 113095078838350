import { Component } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrl: './feedback.component.scss',
    standalone: false
})
export class FeedbackComponent {
  constructor(protected translate: TranslateService) {
  }
}
