<div class="error-header">
    <mat-icon>error</mat-icon>
    <span class="error-dialog-title">{{ "ERROR.TITLE" | translate }}</span><button mat-icon-button class="close-button" [mat-dialog-close]="true"><mat-icon>close</mat-icon></button></div>
<div class="error-panel">
    <div class="center">
        <mat-accordion class="example-headers-align">
            @for (error of data; track error.id; let i = $index) {
                <div class="error-title">{{"ERROR."+error.type.valueOf()+".TITLE" | translate}}</div>
                <div class="error-text">{{"ERROR."+error.type.valueOf()+".TEXT" | translate}} </div>
                <div><button class="error-dialog-button" style="display: inline" mat-button (click)="toggleInfo()">{{(info ? "ERROR.LESS" : "ERROR.MORE") | translate}}</button></div>
                @if (info) {
                <mat-divider></mat-divider>
                <div class="error-detail">{{error.orginialError.detail}}</div>
                }


            }
        </mat-accordion>
    </div>
</div>
<mat-dialog-actions>
    <button mat-raised-button class="error-dialog-button" [mat-dialog-close]="true">{{ "GENERIC.CLOSE" | translate }}</button>
</mat-dialog-actions>

