import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Observable, of} from "rxjs";

@Pipe({
    name: 'dateAgo',
    standalone: false
})
export class DateAgoPipe implements PipeTransform {

  constructor(private translate:  TranslateService) {
  }

  transform(value: any, args?: any): Observable<string> {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      let key = 'JUST_NOW';
      let val =  seconds;
      if (seconds > 29)  {
        const intervals: { [key: string]: number } = {
          'YEAR': 31536000,
          'MONTH': 2592000,
          'WEEK': 604800,
          'DAY': 86400,
          'HOUR': 3600,
          'MINUTE': 60,
          'SECOND': 1
        };
        let counter;
        for (const i in intervals) {
          counter = Math.floor(seconds / intervals[i]);
          if (counter > 0) {
            if (counter === 1) {
              val = counter;
              key = i;
            } else {
              val = counter;
              key = i + 'S'; // plural (2 days ago)
            }
            break;
          }
        }
      }
      return this.translate.get("AGO."+key, {value: val});
    }else {
      return of('ERROR')
    }
  }

}
