import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private readonly termsConditionsConsentVersionKey = 'termsConditionsConsentVersion';
  private readonly userLanguageKey = 'userLanguage';

  constructor() {
  }

  // Getters
  getTermsConditionsConsentVersion(tenant: string): number | null {
    return Number.parseInt(localStorage.getItem(this.termsConditionsConsentVersionKey+"_"+tenant) || '0');
  }

  getUserLanguage(): string | null {
    return localStorage.getItem(this.userLanguageKey);
  }

  // Setters
  setTermsConditionsConsentVersion(tenant: string, version: number): void {
    localStorage.setItem(this.termsConditionsConsentVersionKey+"_"+tenant, version.toString());
  }

  setUserLanguage(lang: string){
    return localStorage.setItem(this.userLanguageKey, lang);
  }

  setChangelogLastDisplayedNow(){
    localStorage.setItem('changelogLastDisplayed', new Date().toISOString());
  }

  getChangelogLastDisplayed(): Date {
    return new Date(Date.parse(localStorage.getItem('changelogLastDisplayed') || new Date(0).toISOString()));
  }

}
