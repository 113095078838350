import {Component, OnInit} from '@angular/core';
import {MsalBroadcastService} from "@azure/msal-angular";
import {AuthError, EventMessage} from "@azure/msal-browser";

@Component({
    selector: 'app-login-failed',
    templateUrl: './login-failed.component.html',
    styleUrl: './login-failed.component.scss',
    standalone: false
})
export class LoginFailedComponent implements OnInit {

  authError: AuthError | null = null;
  constructor(private msalBroadcastService: MsalBroadcastService) { }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .subscribe((result: EventMessage) => {
        console.log(result)
        if (result.error instanceof AuthError) {
          this.authError = result.error;
        }
      });
  }
}
