import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {PromptConfigDTO, PromptDTO} from "../../model/dto.model";

export const PromptLabActions = createActionGroup({
  source: 'PromptLab',
  events: {
    'Load PromptLab': emptyProps(),
    'Load PromptLab Success': props<{ data: PromptConfigDTO }>(),
    'Load PromptLab Failure': props<{ error: any }>(),
    'Load Prompts': emptyProps(),
    'Load Prompts Success': props<{ data: PromptDTO[] }>(),
    'Load Prompts Failure': props<{ error: any }>(),
    'Save Prompt': props<{ prompt: PromptDTO }>(),
    'Save Prompt Success': emptyProps(),
    'Save Prompt Failure': props<{ error: any }>(),
    'Create New Prompt': emptyProps(),
    'Save New Prompt': props<{ prompt: PromptDTO }>(),
    'Delete Prompt': props<{ prompt: PromptDTO }>(),
    'Delete Prompt Success': props<{ label: string }>(),
    'Delete Prompt Failure': props<{ error: any }>()
  }
});
