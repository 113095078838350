import {Component, Input, NgZone} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../redux/reducers";
import {ConversationsActions} from "../../redux/actions/conversations.actions";

@Component({
    selector: 'app-add-conversation-button',
    templateUrl: './add-conversation-button.component.html',
    styleUrl: './add-conversation-button.component.scss',
    standalone: false
})
export class AddConversationButtonComponent {

  activeGroupId$ = this.store.select((state: State) => state.groups.activeGroupId)
  @Input() disabled = false;

  constructor(private store: Store<State>) {

  }

  addConversation(){
    this.store.select("groups", "activeGroupId").subscribe(
        groupId => {
          this.store.dispatch(ConversationsActions.conversationNew({groupId: groupId ||""}))
        }
    )
  }
}
