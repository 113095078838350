import { Component } from '@angular/core';

@Component({
    selector: 'app-admin-overview',
    templateUrl: './admin-overview.component.html',
    styleUrl: './admin-overview.component.scss',
    standalone: false
})
export class AdminOverviewComponent {

}
