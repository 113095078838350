import {Component} from '@angular/core';
import {Color, ScaleType} from "@swimlane/ngx-charts";

@Component({
  selector: 'app-usage-chart',
  standalone: false,
  
  templateUrl: './usage-chart.component.html',
  styleUrl: './usage-chart.component.scss'
})
export class UsageChartComponent {

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Monat';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'Anzahl';
  legendTitle: string = 'Metrik';
  colorScheme: Color = {
    name: "BKW",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#ffcc00', '#d6d700', '#85cfe8']
  };

  data = [
    {
      "name": "September 2024",
      "series": [
        {
          "name": "Anzahl Antworten",
          "value": 10,
          "extra": {
            "code": "de"
          }
        },
        {
          "name": "Anzahl Konversationen",
          "value": 5,
          "extra": {
            "code": "de"
          }
        },
        {
          "name": "Anzahl Nutzer",
          "value": 2,
          "extra": {
            "code": "de"
          }
        }
      ]
    },
    {
      "name": "Oktober 2024",
      "series": [
        {
          "name": "Anzahl Antworten",
          "value": 523,
          "extra": {
            "code": "us"
          }
        },
        {
          "name": "Anzahl Konversationen",
          "value": 110,
          "extra": {
            "code": "us"
          }
        },
        {
          "name": "Anzahl Nutzer",
          "value": 34,
          "extra": {
            "code": "us"
          }
        }
      ]
    },
    {
      "name": "November 2024",
      "series": [
        {
          "name": "Anzahl Antworten",
          "value": 847,
          "extra": {
            "code": "fr"
          }
        },
        {
          "name": "Anzahl Konversationen",
          "value": 110,
          "extra": {
            "code": "fr"
          }
        },
        {
          "name": "Anzahl Nutzer",
          "value": 91,
          "extra": {
            "code": "fr"
          }
        }
      ]
    },
    {
      "name": "Dezember 2024",
      "series": [
        {
          "name": "Anzahl Antworten",
          "value": 2967,
          "extra": {
            "code": "uk"
          }
        },
        {
          "name": "Anzahl Konversationen",
          "value": 1058,
          "extra": {
            "code": "uk"
          }
        },
        {
          "name": "Anzahl Nutzer",
          "value": 944,
          "extra": {
            "code": "uk"
          }
        }
      ]
    }
  ]

}
