import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {PromptLabActions} from "../actions/prompt-lab.actions";
import {catchError, map, of, switchMap, withLatestFrom} from "rxjs";
import {RestService} from "../../service/rest.service";
import {PromptConfigDTO, PromptDTO} from "../../model/dto.model";
import {activeTenant} from "../reducers/group.reducer";
import {Store} from "@ngrx/store";
import {State} from "../reducers";



@Injectable()
export class PromptLabEffects {


  constructor(private actions$: Actions, private restService: RestService, private store: Store<State>) {

  }

  loadPromplab$ = createEffect(() => this.actions$.pipe(
      ofType(PromptLabActions.loadPromptLab),
      withLatestFrom(this.store.select(activeTenant)),
      switchMap((tenant) => this.restService.get<PromptConfigDTO>(`/v0/tenants/${tenant[1]!.id}/prompts/settings`).pipe(
          map(promptlab => {
              return PromptLabActions.loadPromptLabSuccess({data: promptlab})
          }),
          catchError(error => of(PromptLabActions.loadPromptLabFailure({error})))
      ))
  ));

  loadPrompts$ = createEffect(() => this.actions$.pipe(
        ofType(PromptLabActions.loadPrompts),
        withLatestFrom(this.store.select(activeTenant)),
        switchMap((tenant) => this.restService.get<PromptDTO[]>(`/v0/tenants/${tenant[1]!.id}/prompts`).pipe(
            map(prompts => {
                return PromptLabActions.loadPromptsSuccess({data: prompts})
            }),
            catchError(error => of(PromptLabActions.loadPromptsFailure({error})))
        ))
    ));

  savePrompt$ = createEffect(() => this.actions$.pipe(
        ofType(PromptLabActions.savePrompt),
        withLatestFrom(this.store.select(activeTenant)),
        switchMap(([action, tenant]) => this.restService.put<PromptDTO>(`/v0/tenants/${tenant!.id}/prompts/${action.prompt.label}`, action.prompt).pipe(
            map(prompt => {
                return PromptLabActions.savePromptSuccess()
            }),
            catchError(error => of(PromptLabActions.savePromptFailure({error})))
        ))
    ));

    saveNewPrompt$ = createEffect(() => this.actions$.pipe(
        ofType(PromptLabActions.saveNewPrompt),
        withLatestFrom(this.store.select(activeTenant)),
        switchMap(([action, tenant]) => this.restService.post<PromptDTO>(`/v0/tenants/${tenant!.id}/prompts`, action.prompt).pipe(
            map(prompt => {
                return PromptLabActions.savePromptSuccess()
            }),
            catchError(error => of(PromptLabActions.savePromptFailure({error})))
        ))
    ));

    deletePrompt$ = createEffect(() => this.actions$.pipe(
        ofType(PromptLabActions.deletePrompt),
        withLatestFrom(this.store.select(activeTenant)),
        switchMap(([action, tenant]) => this.restService.delete<PromptDTO>(`/v0/tenants/${tenant!.id}/prompts/${action.prompt.label}`).pipe(
            map(prompt => {
                return PromptLabActions.deletePromptSuccess({label: action.prompt.label})
            }),
            catchError(error => of(PromptLabActions.deletePromptFailure({error})))
        ))
    ));


}
