import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {filter, first, Observable} from "rxjs";
import {AccountInfo} from "@azure/msal-browser";
import {AppActions} from "../../redux/actions/app.actions";
import {GroupActions} from "../../redux/actions/group.actions";
import {AppModule} from "../../app.module";
import {LocalStorageService} from "../../service/local-storage.service";
import {Store} from "@ngrx/store";
import {State} from "../../redux/reducers";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {MatSidenav} from "@angular/material/sidenav";
import { version, name } from '../../../../package.json';
import {ActivatedRoute, Router} from "@angular/router";
import {activeTenant} from "../../redux/reducers/group.reducer";
import {ChatComponent} from "../chat/chat.component";
import {ConversationsActions} from "../../redux/actions/conversations.actions";


@Component({
    selector: 'app-tenant-container',
    templateUrl: './tenant-container.component.html',
    styleUrl: './tenant-container.component.scss',
    standalone: false
})
export class TenantContainerComponent implements OnInit, AfterViewInit {

  @Input() sidenav!: MatSidenav
  account: Observable<AccountInfo | null>
  title = 'BKW AI Platform';
  groupsLoaded$ =  this.store.select((state: State) => state.groups.loaded)
  tenants$ = this.store.select((state: State) => state.groups.groups)
  activeGroupId$ = this.store.select((state: State) => state.groups.activeGroupId)
  activeTenant$ = this.store.select((state: State) => state.groups.activeGroup)
  userAvatar$ = this.store.select((state: State) => state.app.userAvatar)
  viewMode$ = this.store.select((state: State) => state.app.viewMode);
  appTitle$ = this.store.select((state: State) => state.app.title);
  protected version: string;
  protected appName: string;
  protected langs = ['de', 'fr', 'en']

  constructor(private router: Router, private activeRoute: ActivatedRoute, private app: AppModule, private localStore: LocalStorageService, private store: Store<State>, protected translate: TranslateService, protected titleService: Title) {
    let browserLang = navigator.language || 'en'
    browserLang = localStore.getUserLanguage() || (this.langs.includes(browserLang.substring(0,2).toLowerCase()) ? browserLang.substring(0,2).toLowerCase() : 'en')
    this.setLang(browserLang)
    this.account = app.account
    this.version = version;
    this.appName = name;

  }

  setLang(lang: string) {
    this.translate.use(lang)
    this.localStore.setUserLanguage(lang)
  }

  ngOnInit(): void {
    this.account.pipe(filter((account): account is AccountInfo => !!account)).subscribe(a => {
      this.store.dispatch(AppActions.appLoginSuccess({userAccount: a}));
    });
    this.store.dispatch(GroupActions.groupsLoad())
    this.appTitle$.subscribe(title => this.titleService.setTitle(title));
    this.activeRoute.params.subscribe((params) => {
      //wait for the groups to be loaded
      this.groupsLoaded$.subscribe((loaded) => {
        if(loaded){
          if (params['tenantId'] !== '') {
            //if we have a tenant id from the url, activate it
            this.tenants$.subscribe((tenants) => {
              let tenant = tenants.filter((group) => group.id === params['tenantId'])
              if (tenant.length > 0) {
                this.store.dispatch(GroupActions.groupsActivate({tenant: tenant[0]}));
              }else{
                //redirect to the default tenant if the tenant id is not found
                console.log("tenant not found "+params['tenantId']);
                this.router.navigate(['']);
              }

              if(this.activeRoute.children.length === 0) {
                this.router.navigate(['tenant', params['tenantId'], 'conversation'], {relativeTo: this.activeRoute.parent})
              }
            })
          }else{
            //if there is no tenant in the url, activate the default tenant
            this.tenants$.subscribe((groups) => {
              if (groups.length > 0) {
                //activate default group
                let defaultGroup = groups.filter((group) => group.default)[0];
                this.router.navigate(['tenant', defaultGroup.id, 'conversation'], { relativeTo: this.activeRoute.parent })
              }
            });
          }
        }
      });
    });
  }

  toggleConversationDrawer() {
    this.store.dispatch(AppActions.appConverationDrawerToggle())
  }


  ngAfterViewInit() {

    //Acknowledge compliance dialog
    this.activeTenant$.subscribe(tenant => {
      if(tenant){
        if(this.localStore.getTermsConditionsConsentVersion(tenant.id) !== tenant.tc.version){
          this.app.showComplianceDialog(tenant)
        }
      }

    });

  }

  showComplianceDialog() {
    this.activeTenant$.pipe(first()).subscribe(tenant => {
      if(tenant)
        this.app.showComplianceDialog(tenant)
    })
  }

  onDestroy(component: any) {
    if (component instanceof ChatComponent){
      this.store.dispatch(ConversationsActions.deactivateActiveConversation());
    }
  }

  protected readonly activeTenant = activeTenant;
}
