import {Component, HostBinding, OnInit} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    standalone: false
})
export class AppComponent implements OnInit{
  @HostBinding('class')
  currentTheme: 'light-theme' | 'dark-theme' = 'light-theme';

  constructor(
      private matIconReg: MatIconRegistry
  ) {}


  ngOnInit(): void {
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
  }

}
