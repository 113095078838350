import { Pipe, PipeTransform } from '@angular/core';
import {ConversationStub} from "../model/conversation.model";

@Pipe({
    name: 'filterConversations',
    standalone: false
})
export class FilterConversationsPipe implements PipeTransform {

  transform(value: ConversationStub[], type: string): ConversationStub[] {
    if (type == 'all')
      return value;
    else
      return value.filter((conversation) => conversation.visibility == type);
  }

}
