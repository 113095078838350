import { Component } from '@angular/core';
import {State} from "../../redux/reducers";
import {Store} from "@ngrx/store";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-admin-container',
  standalone: false,
  
  templateUrl: './admin-container.component.html',
  styleUrl: './admin-container.component.scss'
})
export class AdminContainerComponent {

  conversationDrawerState$ = this.store.select((state: State) => state.app.conversationPanelOpen)

    constructor(protected store: Store<State>, protected route: ActivatedRoute) {
    }



}
