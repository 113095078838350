<button mat-icon-button [matMenuTriggerFor]="promptMenu" *ngIf="((prompts | async)?.length || 0) > 1" [matTooltip]="(activePrompt$ | async)?.title || ''">
    @if (selected | async) {
        <ng-container>
        <mat-icon [ngStyle]="{color: (selected | async)!.appearance.color}">{{ (selected | async)!.appearance.icon }}</mat-icon>
        </ng-container>
    } @else {
        <ng-container>
        <mat-icon [ngStyle]="{color: 'gray'}">fiber_manual_record</mat-icon>
        </ng-container>
    }
</button>
<mat-menu #promptMenu="matMenu">
    <button mat-menu-item *ngFor="let p of prompts | async" (click)="store.dispatch(GroupActions.groupsActivatePrompt({prompt: p}))">
        <mat-icon [ngStyle]="{color: p.appearance.color}">{{ p.appearance.icon }}</mat-icon>{{p.title}}
    </button>
</mat-menu>