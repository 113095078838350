import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalGuard} from "@azure/msal-angular";
import {LoginFailedComponent} from "./components/login-failed/login-failed.component";
import {ChatComponent} from "./components/chat/chat.component";
import {TenantContainerComponent} from "./components/tenant-container/tenant-container.component";
import {AdminContainerComponent} from "./components/admin-container/admin-container.component";
import {AdminOverviewComponent} from "./components/admin-overview/admin-overview.component";
import {AdminPromptlabComponent} from "./components/admin-promptlab/admin-promptlab.component";

const routes: Routes = [
  {
    path: 'tenant/:tenantId',
    component: TenantContainerComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'conversation/:conversationId',
        component: ChatComponent
      },
      {
        path: 'conversation',
        redirectTo: 'conversation/',
        pathMatch: 'full'
      },
      {
        path: 'admin',
        component: AdminContainerComponent,
        children: [
          {
            path: '',
            redirectTo: 'promptlab',
            pathMatch: 'full'
          },
            {

          path: 'overview',
          component: AdminOverviewComponent
        },
          {

            path: 'promptlab',
            component: AdminPromptlabComponent
          }
        ]
      }
    ]
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent
  },
  {
    path: 'tenant',
    pathMatch: 'full',
    redirectTo: 'tenant/'
  },
  { path: '',
  redirectTo: 'tenant/',
    pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    initialNavigation: 'enabledNonBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
