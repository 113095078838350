import {Component, Input} from '@angular/core';
import {GroupActions} from "../../redux/actions/group.actions";
import {Observable, of} from "rxjs";
import {Prompt} from "../../model/prompt.model";
import {Store} from "@ngrx/store";
import {State} from "../../redux/reducers";
import {activePrompt} from "../../redux/reducers/group.reducer";

@Component({
    selector: 'app-prompt-selection',
    templateUrl: './prompt-selection.component.html',
    styleUrl: './prompt-selection.component.scss',
    standalone: false
})
export class PromptSelectionComponent {

    @Input() prompts: Observable<Prompt[]> = of([]);
    @Input() selected: Observable<Prompt | undefined> = of(undefined);
    protected readonly GroupActions = GroupActions;
    protected activePrompt$ = this.store.select(activePrompt);

    constructor(protected store: Store<State>) {
    }

    protected readonly activePrompt = activePrompt;
}
