<div class="changelog-header">
    <mat-icon>featured_seasonal_and_gifts</mat-icon>
    <div class="changelog-title">{{ 'CHANGELOG.TITLE' | translate }}</div>
    <button mat-icon-button class="close-button" [mat-dialog-close]="true"><mat-icon>close</mat-icon></button>
    </div>
<div class="changelog-panel">
    <table *ngIf="!showAll">
        @for (version of data.new; track version.date) {
            <span *ngIf="version.version">
            <tr><strong>{{version.date}}- {{version.version}}</strong> </tr>
        <tr>
            <td colspan="2"><markdown>{{ version.body }}</markdown></td>
        </tr>
                </span>
        }
    </table>
    <table *ngIf="showAll">
        @for (version of data.all; track version.date) {
            <span *ngIf="version.version">
            <tr><strong>{{version.date}}- {{version.version}}</strong> </tr>
        <tr>
            <td colspan="2"><markdown>{{ version.body }}</markdown></td>
        </tr>
                </span>
        }
    </table>
</div>
<mat-dialog-actions>
    <button mat-raised-button  [mat-dialog-close]="true">{{ "GENERIC.OK" | translate }}</button>
</mat-dialog-actions>