import {createReducer, on} from '@ngrx/store';
import {AppActions} from '../actions/app.actions';
import {AccountInfo} from "@azure/msal-browser";
import {SafeUrl} from "@angular/platform-browser";
import {ErrorMessage, ErrorMessageType} from "../../model/error-message.model";
import {ConversationsActions} from "../actions/conversations.actions";
import {GroupActions} from "../actions/group.actions";

export interface AppState {
    conversationPanelOpen: boolean;
    tipsPanelOpen: boolean;
    userAccount?: AccountInfo;
    userAvatar?: SafeUrl;
    viewMode: string;
    errors: ErrorMessage[];
    title: string;
    complianceVersionAcknowledged: number;
    busy: boolean;
    busyStateChange: Date;
}


export const initialState: AppState = {


    conversationPanelOpen: true,
    tipsPanelOpen: false,
    viewMode: "desktop",
    errors: [],
    title: "BKW AI Platform",
    complianceVersionAcknowledged: 0,
    busy: false,
    busyStateChange: new Date()
};

export const reducer = createReducer(
  initialState,
    on(AppActions.appConversationDrawerOpen, state => ({...state, conversationPanelOpen: true})),
    on(AppActions.appConversationDrawerClose, state => ({...state, conversationPanelOpen: false})),
    on(AppActions.appTipsPanelOpen, state => ({...state, tipsPanelOpen: true})),
    on(AppActions.appTipsPanelClose, state => ({...state, tipsPanelOpen: false})),
    on(AppActions.appConverationDrawerToggle, state => ({...state, conversationPanelOpen: !state.conversationPanelOpen})),
    on(AppActions.appTipsPanelToggle, state => ({...state, tipsPanelOpen: !state.tipsPanelOpen})),
    on(AppActions.appLoginSuccess, (state, account) =>  ({...state, userAccount: account.userAccount})),
    on(AppActions.appAvatarLoadSuccess, (state, avatar) => ({...state, userAvatar: avatar.userAvatar})),
    on(AppActions.appUpdateViewMode, (state, viewMode) => ({...state, viewMode: viewMode.viewMode})),
    on(ConversationsActions.conversationUploadFileFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.UPLOAD_FILE_FAILED)]})),
    on(ConversationsActions.conversationNewFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.CREATE_CONVERSATION_FAILED)]})),
    on(ConversationsActions.conversationRenameFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.CONVERSATION_RENAME_FAILED)]})),
    on(ConversationsActions.conversationsLoadFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.CONVERSATIONS_LOAD_FAILED)]})),
    on(ConversationsActions.conversationDetailLoadFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.CONVERSATION_DETAIL_LOAD_FAILURE)]})),
    on(ConversationsActions.conversationDetailAddResponseFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.CONVERSATION_ADD_RESPONSE_FAILED)]})),
    on(ConversationsActions.conversationDeleteFileFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.DELETE_FILE_FAILED)]})),
    on(GroupActions.groupsLoadFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.GROUP_LOAD_FAILED)]})),
    on(GroupActions.groupsActivate, (state, a) => ({...state, title: a.tenant.name})),
    on(AppActions.appAcknowledgeComplianceDialog, (state, version) => ({...state, complianceVersionAcknowledged: version.version})),
    on(AppActions.appUpdateTitle, (state, title) => ({...state, title: title.title})),
    on(AppActions.appBusy, (state) => ({...state, busy: true, busyStateChange: new Date()})),
    on(AppActions.appIdle, (state) => ({...state, busy: false, busyStateChange: new Date()})),
    on(AppActions.appClearErrors, (state) => ({...state, errors: []}))
    );

