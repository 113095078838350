import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'orderConversationBy',
    standalone: false
})

export class OrderByPipe implements PipeTransform {
  transform(value: any, propName: string, order: string = 'asc') {

    value = [...value];

    const asc = order.toLowerCase() === 'asc';

    return (value as Array<any>).sort((a, b) => {
      const da = new Date(a[propName]);
      const db = new Date(b[propName]);
      if (da.getTime() < db.getTime()) {
        return asc ? -1 : 1;
      } else if (da.getTime() == db.getTime()) {
        return 0;
      } else if (da.getTime() > db.getTime()) {
        return asc ? 1 : -1;
      }
      return 0;
    });

  }
}
