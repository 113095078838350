export const environment = {
  app: {
    defaultPromptAppearance: {
      color: 'orange',
      icon: 'fiber_manual_record'

    }
  },
  production: false,
  apiBase: 'https://api-t.bkw.ch/apit/gpt4km',
  apiScope: 'api://a9dd9466-80c2-41f6-aad9-89ebfe9c230b/Api.Access',
  wsBase: 'ws://localhost:8080',
  msalConfig: {
    auth: {
      clientId: 'aa492838-3099-455b-99c4-94eac8d97278',
      authority: 'https://login.microsoftonline.com/376e33ca-d656-4e46-8bcb-7d5ba898ae66',
    }
  },
  appInsights: {
    instrumentationKey: 'ea97f4f0-968e-417f-bf03-f3d78f2531d0'
  }
}
