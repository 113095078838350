<mat-drawer-container class="chat-container">
    <mat-drawer #conversationList [mode]="drawerMode" [opened]="conversationDrawerState$ | async" class="conversation-list">
        <div class="conversation-header">
            <div style="vertical-align: middle;">{{"CONV.TITLE" | translate}}</div>
            <div>
                <app-add-conversation-button [disabled]="(appBusyState$ | async) || false"></app-add-conversation-button>
            </div>
        </div>
        <mat-button-toggle-group class="conversation-filter" [(ngModel)]="visibilityFilter" *ngIf="((conversations$ |async) || [] | filterConversations:'public').length > 0">
            <mat-button-toggle value="public" class="conversation-filter-button">{{'CONV.PUBLIC' | translate}}</mat-button-toggle>
            <mat-button-toggle value="private" class="conversation-filter-button">{{'CONV.PRIVATE' | translate}}</mat-button-toggle>
            <mat-button-toggle value="all" class="conversation-filter-button">{{'CONV.ALL' | translate}}</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-nav-list>
            @for (conversation of ((conversations$ |async) || [] | orderConversationBy :'modified_at':'desc'); track conversation.id; let idx = $index) {
                <mat-list-item *ngIf="visibilityFilter == 'all' || visibilityFilter == conversation.visibility || (activeConversationId$ | async) == conversation.id" [id]="'conv' + conversation.id" [activated]="(activeConversationId$ | async) == conversation.id" routerLink="../../conversation/{{conversation.id}}">
                        <div matListItemTitle>{{conversation.title || "CONV.UNTITLED" | translate}}</div>
                       <p matListItemLine>{{conversation.modified_at | dateAgo | async }}</p>
                    <p matListItemMeta>
                        <button *ngIf="(activeConversationId$ | async) == conversation.id" mat-icon-button style="--mat-icon-button-state-layer-color: none;" [disableRipple]="false" [matMenuTriggerFor]="conversationMenu" [matMenuTriggerData]="{id: conversation.id, title: conversation.title}" [matMenuTriggerRestoreFocus]="false"><mat-icon>more_vert</mat-icon></button>
                        <mat-icon *ngIf="conversation.visibility == 'public'" [matTooltip]="'CONV.PUBLIC_TOOLTIP' | translate" class="conversation-visibility-icon">groups</mat-icon>
                    </p>
                </mat-list-item>
            }
            <mat-menu #conversationMenu="matMenu">
                <ng-template matMenuContent let-id="id" let-title="title">
                    <button mat-menu-item (click)="editTitle($event, id, title);"><mat-icon>edit</mat-icon>
                        {{ 'CONV.EDIT_TITLE' | translate }}</button>
                    <button mat-menu-item (click)="deleteConversation($event, id)"><mat-icon class="delete-icon">delete</mat-icon>
                        {{ 'CONV.DELETE' | translate }}</button>
                </ng-template>
            </mat-menu>
        </mat-nav-list>

        <div class="feedback-container"><button mat-fab (click)="openFeedbackPanel()">
            <mat-icon>feedback</mat-icon>
        </button></div>
    </mat-drawer>
    <mat-drawer-content class="chat-panel">
        <div class="scroll-container" #chatScrollContainer>
            <div class="chat-conversation" #chatMessages>
                <mat-card  appearance="outlined" class="chat-llm-answer-card" *ngIf="(activeConversationId$ | async) && ((conversationDetail$ | async)?.messages || []).length == 0">
                    <mat-card-content class="chat-llm-answer">
                        <div markdown [ngClass]="intro_class" [data] ="'CONV.INTRO' | translate">
                        </div>
                        <div class="chat-llm-answer-controls">
                            <div class="control-copy"></div>
                            <div class="control-streaming"></div>
                            <div class="control-sources"></div>
                        </div>
                    </mat-card-content>
                </mat-card>
                @for (element of (conversationDetail$ |async)?.messages; track elementKey(element);) {
                    <mat-card  appearance="outlined" class="chat-user-prompt">
                    <mat-card-content>
                        <p markdown>{{element.question}}</p>
                    </mat-card-content>
                </mat-card>
                    <mat-card  appearance="outlined" class="chat-llm-answer-card" *ngIf="!element.ongoing">
                        <mat-card-content class="chat-llm-answer">
                            <div class="chat-llm-answer-text">
                            <markdown katex  [katexOptions]="katexOptions">
                                {{sanitizeContent(element.answer)}}


                            </markdown>
                                <div *ngIf="showDetailFor == element.id" (mouseout)="hideInfo()" class="answer-info-container">
                                    <mat-divider></mat-divider>
                                    <div class="answer-info-row">
                                    <mat-icon inline [ngStyle]="{color: (activePrompt$ | async)!.appearance.color}">{{ (activePrompt$ | async)!.appearance.icon }}</mat-icon> {{(activePrompt$ | async)?.title}}
                                    </div>
                                    </div>
                                <div>
                                    <button mat-icon-button class="control-button" (mouseover)="showInfo(element.id)"><mat-icon>info</mat-icon></button>
                                </div>
                            </div>
                            <div class="chat-llm-answer-controls">
                                <div class="control-copy" ><button mat-icon-button class="control-button" (click)="copyClipboard(element.answer)"><mat-icon>content_copy</mat-icon></button></div>
                                <div class="control-streaming"></div>
                                <div class="control-sources">
                                    <div *ngIf="showFeedback == element.id">

                                    </div>
                                    <button mat-icon-button class="control-button" (click)="showFeedbackFor(element.id)"><mat-icon>thumbs_up_down</mat-icon></button></div>
                            </div>
                        </mat-card-content>
                        <mat-card-footer>
                            <mat-chip-set>
                                @for (group of groupByTopic(element.documents) | keyvalue; track group.key;) {
                                <mat-basic-chip (click)="toggleSourceTopic(element.id + group.key)" matBadgePosition="above after" [matBadge]="castToRetrievedDocument(group.value).length">{{mapTopicName((activeTenant$ | async)?.topics || [], group.key) }}</mat-basic-chip>
                            }
                            </mat-chip-set>
                            @for (group of groupByTopic(element.documents) | keyvalue; track group.key) {
                                <div [id]="element.id + group.key" [hidden]="visibleSourceTopic !== element.id + group.key" >
                                    <mat-nav-list>
                                @for(source of castToRetrievedDocument(group.value); track source; let idx = $index) {
                                    <a mat-list-item href="{{source.topic == 'AD_HOC_UPLOAD' ? '#' : source.url}}" [target]="source.topic == 'AD_HOC_UPLOAD' ? '_self' : '_blank'"><div *ngIf="source.topic !== 'AD_HOC_UPLOAD'" matListItemMeta><mat-icon>open_in_new</mat-icon></div>{{(idx+1) + ". " + source.title}}</a>
                                }
                                    </mat-nav-list>
                                </div>

                            }
                        </mat-card-footer>
                    </mat-card>
                }
                <mat-card appearance="outlined" class="chat-llm-answer-card" *ngIf="(transientResponse$ | async)?.ongoing" >
                    <mat-card-content class="chat-llm-answer">
                        <app-streaming-container [stream]="transientResponse$" [hidden]="false"></app-streaming-container>
                        <div class="chat-llm-answer-controls">
                            <div class="control-streaming"><mat-spinner diameter="16" strokeWidth="2"></mat-spinner></div>
                        </div>
                    </mat-card-content>
                    <mat-card-footer>
                    </mat-card-footer>
                </mat-card>
            </div>
        </div>
        <div class="chat-footer-container">
            <div class="chat-question-field">
            <mat-form-field subscriptSizing="dynamic" class="chat-input" appearance="outline">
                <textarea
                        autofocus
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="20"
                        class="chat-input-textarea"
                        placeholder="{{'CONV.ASK_PLACEHOLDER' | translate}}"
                        matInput
                        maxlength="5000"
                        #inputMessage
                        [disabled]="((activeConversationId$ | async)?true:false) && (appBusyState$ | async) || false"
                        [(ngModel)]="message"
                        (keyup.enter)="addUserPrompt(message)"
                ></textarea>
                <span *ngIf="message.length > showLengthIndicatorAfter" class="remaning">{{message.length}}/{{ maxMessageLength }}</span>
                <mat-toolbar class="action-buttons">
                    <mat-toolbar-row>
                        <button mat-icon-button
                                (click)="addFile()"
                                [matBadge]="(conversationDetail$ |async)?.documents?.length"
                                [matBadgeHidden]="(conversationDetail$ |async)?.documents?.length == 0"
                                matBadgeOverlap="true"
                                [disabled]="((activeConversationId$ | async) ? false : true) || (appBusyState$ | async) || false"
                                [matTooltip]="('FILE.INFOTEXT' | translate : { value: 2 - ((conversationDetail$ | async)?.documents?.length || 0), total: 2 })"

                        >
                            <mat-icon>attach_file_add</mat-icon>
                        </button>
                        <button mat-icon-button [matMenuTriggerFor]="topicMenu" [disabled]="(appBusyState$ | async) || false" *ngIf="undefinedOrEmpty((activePrompt$ | async)?.availableTopics)" [matBadge]="(activeTopics$ | async)?.length" [matBadgeHidden]="(activeTopics$ | async)?.length == 0">
                            <mat-icon [matBadge]="">document_search</mat-icon>
                        </button>
                        <mat-menu #topicMenu="matMenu" [overlapTrigger]="false">
                            <mat-checkbox *ngFor="let topic of (activePrompt$ | async)?.availableTopics" (click)="$event.stopPropagation();" (change)="toggleTopic($event, topic)" [checked]="(activeTopics$ | async)?.includes(topic)">
                                {{ extractTopicName((activeTenant$ | async)?.topics || [], topic) }}
                            </mat-checkbox>
                        </mat-menu>
                        <app-prompt-selection [prompts]="prompts$" [selected]="activePrompt$"></app-prompt-selection>
                        <div class="chat-disclaimer-container"
                             matTooltip="{{ 'CONV.DISCLAIMER' | translate }}"
                             matTooltipShowDelay="2000"
                             matTooltipHideDelay="500"
                        >{{ 'CONV.DISCLAIMER' | translate }}</div>
                        <button
                                mat-icon-button
                                color="primary"
                                [disabled]="((activeConversationId$ | async) ? false : true) || (appBusyState$ | async) || false"
                                (click)="addUserPrompt(message);">
                            <mat-icon>send</mat-icon>
                        </button>
                    </mat-toolbar-row>
                </mat-toolbar>
            </mat-form-field>
            </div>

        </div>
    </mat-drawer-content>
</mat-drawer-container>

